// imports
import { FC, Fragment, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Autocomplete, Box, CircularProgress, MenuItem, TextField, debounce } from '@mui/material';
//interfaces , constants , helper, svgs, graphql
import { SearchInputIcon } from 'assets/svgs';
import { renderIngredients } from 'lib/helper';
import { HTTP_STATUS } from 'constants/index';
import { IngredientItemFormType, IngredientSelectorProps } from 'interfaces';
import {
  IngredientsPayload,
  FormulaIngredientUnit,
  useFindAllIngredientsForSelectorLazyQuery,
} from 'generated/graphql';

const IngredientSelector: FC<IngredientSelectorProps> = ({
  name,
  title,
  disabled,
  primaryId,
  isRequired,
  secondaryId,
  handleChange,
  margin = 'dense',
  isMultiple = false,
}) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [getLoading, setGetLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<IngredientsPayload['data']>([]);
  const [selectedOptions, setSelectedOptions] = useState<IngredientItemFormType[]>([]);

  const [findAll, { loading }] = useFindAllIngredientsForSelectorLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllIngredients } = data;
      const { data: ingredients, response, pagination } = findAllIngredients || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { totalPages: pages } = pagination || {};
        setTotalPages(pages);
        setOptions([
          ...(getLoading ? [] : options || []),
          ...((ingredients as IngredientsPayload['data']) ?? []),
        ]);
        setGetLoading(false);
      } else {
        setGetLoading(false);
      }
    },
    onError: () => {
      setGetLoading(false);

      setOptions([]);
    },
  });

  const fetchIngredients = useCallback(
    async (page?: number, query?: string) => {
      if (!page) setGetLoading(true);
      await findAll({
        variables: {
          findAllIngredientsInput: {
            paginationOptions: { page: page || 1, limit: 50 },
            search: query,
            isActive: true,
            ...(primaryId && { categoryId: primaryId }),
            ...(secondaryId && { subCategoryId: secondaryId }),
          },
        },
      });
    },
    [findAll, primaryId, secondaryId],
  );

  useEffect(() => {
    fetchIngredients();
  }, [fetchIngredients]);

  const lastElementRef = useCallback(
    (node: HTMLLIElement) => {
      if (loading) return;
      if (observer?.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        // fetch the coming ingredients if the last node and page is less than or equal to the total page.
        if (entries[0].isIntersecting && page <= totalPages) {
          fetchIngredients(page + 1, searchQuery);
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, page, totalPages, fetchIngredients, searchQuery],
  );

  // Function to handle search with debouncing
  const debouncedSearch = debounce((searchTerm: string) => {
    fetchIngredients(1, searchTerm);
  }, 700); // Adjust the delay time as needed

  const onSearch = (val: string) => {
    debouncedSearch(val);
    setPage(1);
    setOptions([]);
    setSearchQuery(val);
  };

  const onClose = () => {
    setPage(1);
    setOptions([]);
    setSearchQuery('');
    fetchIngredients(1);
  };

  const updatedOptions = useMemo(() => {
    if (options) {
      return renderIngredients(options ?? []);
    }
    return [];
  }, [options]);

  return (
    <Autocomplete
      disableClearable
      popupIcon={<></>}
      disabled={disabled}
      multiple={isMultiple}
      value={selectedOptions}
      renderOption={(props, option) => {
        const { value: val, name: ingredName } = option || {};
        return (
          <MenuItem {...props} key={val} ref={lastElementRef}>
            {ingredName}
          </MenuItem>
        );
      }}
      options={getLoading ? [] : updatedOptions}
      renderTags={() => <></>}
      onClose={onClose}
      getOptionLabel={(option) => option.name || ''}
      loading={loading}
      onChange={(_, value, reason, detail) => {
        const { option } = detail || {};
        const { name, potency, value: ingredientId } = option || {};
        setSelectedOptions(value as IngredientItemFormType[]);
        handleChange &&
          handleChange(
            {
              type: '',
              value: '',
              name: name || '',
              potency: potency ?? 1,
              unit: FormulaIngredientUnit.Mg,
              ingredientPotency: potency ?? 1,
              ingredientId: ingredientId || '',
            },
            reason,
          );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          margin={margin}
          value={searchQuery}
          variant="outlined"
          label={isRequired ? `${title} *` : title}
          onChange={(e) => onSearch(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Fragment>
                <Box px={1} display="flex" alignItems="center">
                  <SearchInputIcon />
                </Box>
                {params.InputProps.startAdornment}
              </Fragment>
            ),
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}>
          {updatedOptions?.map((item) => {
            const { name, value } = item;
            return (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
};

export default IngredientSelector;
